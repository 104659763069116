const roadmapData = [
  {
    quarter: 'Первый квартал',
    tasks: [
      {
        title: 'Исследование рынка',
        items: [
          'анализ конкурентов',
          'определение целевой аудитории',
          'изучение потребностей рынка',
        ],
      },
      {
        title: 'Разработка концепции',
        items: [
          'определение функциональности',
          'разработка бизнес-модели',
          'создание прототипа',
        ],
      },
      {
        title: 'Формирование команды',
        items: ['поиск и привлечение опытных специалистов'],
      },
    ],
  },
  {
    quarter: 'Второй квартал',
    tasks: [
      {
        title: 'Разработка MVP',
        items: [
          'запуск минимально жизнеспособного продукта',
          'тестирование',
          'доработка функциональности',
          'получение обратной связи от пользователей',
        ],
      },
      {
        title: 'Привлечение инвестиций',
        items: ['подготовка инвестиционного предложения', 'поиск инвесторов'],
      },
      {
        title: 'Маркетинг',
        items: ['запуск рекламной кампании', 'формирование сообщества'],
      },
    ],
  },
  {
    quarter: 'Третий квартал',
    tasks: [
      {
        title: 'Развитие платформы',
        items: [
          'добавление новых функций',
          'расширение возможностей',
          'улучшение пользовательского опыта',
        ],
      },
      {
        title: 'Привлечение пользователей',
        items: ['проведение конкурсов и акций', 'сотрудничество с инфлюенсерами'],
      },
      {
        title: 'Партнерство',
        items: ['с другими компаниями в сфере Web3', 'с вет клиниками, зоомагазинами'],
      },
    ],
  },
  {
    quarter: 'Четвертый квартал',
    tasks: [
      {
        title: 'Выход на новые рынки',
        items: [
          'адаптация платформы для разных стран',
          'перевод интерфейса на другие языки',
        ],
      },
      {
        title: 'Масштабирование',
        items: [
          'увеличение числа пользователей',
          'расширение команды',
          'привлечение дополнительных инвестиций',
        ],
      },
    ],
  },
]

export { roadmapData }
