export const faqData = [
  {
    id: '1',
    question: 'Какие функции будут доступны в социальной сети для животных Tail Talks?',
    answer: [`Tail Talks предлагает богатый набор возможностей: от создания уникальных профилей для ваших четвероногих друзей до обмена их фотографиями с другими пользователями. Вы можете украсить их лучшие моменты, превратив фото в цифровые произведения искусства NFT, а также делиться радостью участия в конкурсах и получения наград.`,
      `Социальная сеть также предоставляет возможность организации встреч для прогулок и вовлечения в благотворительные инициативы, все это способствует налаживанию контактов между владельцами и их питомцами`
    ]
  },
  {
    id: '2',
    question: 'Смогу ли я создать профиль для нескольких своих животных?',
    answer: ['Конечно, вы можете создать отдельные профили для каждого вашего питомца. Это позволит вам делиться их уникальными историями, фотографиями и особыми моментами с сообществом любителей животных. Наша социальная сеть станет идеальным местом для того, чтобы показать миру индивидуальность и очарование каждого вашего хвостатого друга.']
  },
  {
    id: '3',
    question: 'Как я могу найти других пользователей с похожими интересами?',
    answer: ['В нашей социальной сети вы можете указать свои интересы прямо в профиле, и наш интеллектуальный поиск автоматически подберет для вас подходящий контент и предложит знакомства с пользователями, у которых схожие увлечения. Кроме того, вы сможете присоединяться к сообществам, которые разделяют ваши интересы, углубляя ваше взаимодействие с единомышленниками.']
  },
  {
    id: '4',
    question: 'Каковы правила сообщества и что запрещено к публикации?',
    answer: [`В нашем уютном уголке, посвященном домашним питомцам, мы стремимся создать атмосферу доброты и уважения. Здесь каждый участник — как часть большой семьи, где царят гармония и взаимопонимание. Мы верим, что слова могут лечить, но и ранить, поэтому призываем всех общаться с теплотой и заботой. Наше сообщество — это оазис, где нет места грубости и оскорблениям.`,
      `Мы также осознаем важность безопасности и комфорта в виртуальном пространстве. Поэтому мы настоятельно просим воздержаться от публикации контента, который может шокировать или вызвать негативные эмоции. Контент для взрослых, изображения жестокости или другие материалы 18+ противоречат духу нашего сообщества и будут немедленно удаляться.`,
      `Давайте вместе создадим место, где каждый чувствует себя как дома, где каждый питомец и его хозяин найдут друзей, поддержку и позитив. Ведь именно такие ценности лежат в основе нашей социальной сети для домашних животных️`]
  },
  {
    id: '5',
    question: 'Есть ли функции для помощи бездомным животным или тем, кто ищет новый дом?',
    answer: ['Да, наша социальная сеть предусматривает функции для поддержки бездомных животных. Мы активно работаем над партнерством с благотворительными организациями и фондами, чтобы оказывать помощь нуждающимся питомцам. Пользователи смогут участвовать в мероприятиях и акциях, направленных на поиск нового дома для животных, а также вносить свой вклад в улучшение их жизни.']
  },
]
